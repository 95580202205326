/* Admin Layout elements */

.adminNavWrapper{
    background-color:var(--background2);
    color: var(--foreground2);
    padding:5rem 0;
}
.adminNavWrapper > * {
    width: var(--contentWidth);
    margin:0 auto;  
}
.adminContent{
    width: var(--contentWidth);
    margin:0 auto;  
    padding:5rem 0;
}

.adminNav{
    display: flex;
    flex-direction: row;
    gap: var(--gap);
    padding:3rem 0 0;
}
.adminNav a {
    color: var(--foreground2);
    border: 0.1rem solid var(--foreground2);
    padding: 0.5rem 1.5rem;
    text-decoration: none;
    border-radius: var(--border-radius);
}
.adminNav a.selected {
    color: var(--foreground);
    background: var(--background)
}