/* Page Layout elements */

.introWrapper{
    background-color:var(--background2);
    color: var(--foreground2);
}
.pageIntroContent{
    width: var(--contentWidth);
    margin:0 auto;  
    padding:5rem 0;
}
.pageContent{
    width: var(--contentWidth);
    margin:0 auto; 
    padding:2rem 0;
}