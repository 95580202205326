.gridTable thead tr {
    background-color: var(--background3);
}
.gridTable th,
.gridTable td {
    padding: 0.3rem 1.5rem;
    text-align: left;
    white-space: nowrap;
}
.gridTable td.rightAligned {
    text-align: right;
}
