.rowHeading{
    display:grid;
    grid-template-columns: repeat(var(--rowCols, 3), 1fr);
    column-gap: var(--gap);
}
.row {
    display:grid;
    grid-template-columns: repeat(var(--rowCols, 3), 1fr);
    column-gap: var(--gap); 
    border-bottom:1px solid var(--background3);
    padding-bottom:1rem;
    margin-bottom:1rem;
}

.row div,
.rowHeading div {
    padding: 0.5rem 1rem;
    white-space:nowrap;
}
.rowHeading div {
    background-color: var(--background3);
}

.resultsTable thead tr {
    background-color: var(--background3);
}
.resultsTable th,
.resultsTable td {
    padding: 0.3rem 1.5rem;
    text-align: left;
    white-space: nowrap;
}
.resultsTable td.timeCell {
    text-align: right;
}