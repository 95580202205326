/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Lato:wght@400;700&display=swap');
/* END Fonts */


/* Normalize */
html,
body{
    margin:0;
    padding:0;
}
* {
    padding: 0;
    margin: 0;
}
/* END Normalize */

/* CSS Custom Properties */
:root {

    /***** Fonts *****/
    --fontFamilyBody: 'Lato', sans-serif;
    --fontFamilyHeading: 'Josefin Sans', sans-serif;
    --fontSizeBody: 1.8rem;
    --lineHeightBody: 1.5;
    --fontSizeH1: 4.8rem;
    --lineHeightH1: 0.75;
    --fontSizeH2: 3.6rem;
    --lineHeightH2: 0.9;
    --fontSizeH3: 2.8rem;
    --lineHeightH3: 1.1;
    --fontSizeH4: 2rem;
    --lineHeightH4: 1.3;

    /***** Sizing *****/
    --max-width: 1440px;
    --contentWidth:80%;
    --border-radius: 0.8rem;

    /***** Spacing *****/
    --section-vertical-spacing: 4rem;
    --internal-vspace: 2rem;

    /***** Grid *****/
    --col-num: 12;
    --gap: 2rem;
    --col-width: calc((100% - (var(--gap) * (var(--col-num) - 1))) / var(--col-num) );

    /***** Colors *****/
    --foreground: #000;
    --background: #fff;
    --foreground2: #fff;
    --background2: #000;
    --foreground3: #000;
    --background3: #D0D0D0;
    --heading: #ffffff;
    --highlight: #1D83FB;

}
/* END CSS Custom Properties */

/* Overall Styles */
* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body,
#root{
    width:100%;
    height:100%;
}

html {
    /* Makes it easier to use rem values */
    font-size: 62.5%;
}

body{
    width:100%;
    height:100%;
    font-family:var(--fontFamilyBody);
    font-size: var(--fontSizeBody);
    line-height: var(--lineHeightBody);
}
/* END Overall Styles */

/* Application Layout */
#app{
    min-height: 100%;
    display: grid;
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 100%;  
}
/* END application layout */

/* Common elements */
h1 {
    font-family:var(--fontFamilyHeading);
    font-size: var(--fontSizeH1);
    font-weight:700;
    line-height: var(--lineHeightH1);
}
h2{
    font-family:var(--fontFamilyHeading);
    font-size: var(--fontSizeH2);
    line-height: var(--lineHeightH2);
    font-weight:normal;
}
h3{
    font-family:var(--fontFamilyHeading);
    font-size: var(--fontSizeH3);
    line-height: var(--lineHeightH3);
    font-weight:normal;
}
h4{
    font-family:var(--fontFamilyHeading);
    font-size: var(--fontSizeH4);
    line-height: var(--lineHeightH4);
    font-weight:normal;
}
p {
    max-width: 60rem;
}
button{
    padding: 5px 10px;
}
/* END common elements */