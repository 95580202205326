.headerWrapper {
    width: var(--contentWidth);
    margin:0 auto; 
    padding:2rem 0;
}
.headerWrapper h1{
    margin:0;
    
}
.headerContent{
    display: flex;
    align-items:center;
    justify-content:space-between;

}
.headerWrapper a {
    text-decoration: none;
    color: var(--foreground);
    font-size: var(--fontSizeh3);
    padding: 1.4rem 2.5rem;
    border-right: 1px solid var(--foreground);
}
.headerWrapper a:last-child{
    border-right:none;
}

.headerWrapper a:hover {
    background-color: var(--foreground);
    color: var(--background);
}